@use "../../scss/variables.scss" as var;

.results {
  width: 100%;
}

.results__item {
  font-style: italic;
  font-weight: var.$font-weight-bold;
  font-size: 3.5rem;
  line-height: 3.85rem;
}

.results__user {
  color: var.$clr-purple;
}

.results__field {
  padding: 0 0 0 0.175em;
  color: var.$clr-black;
}

@media (min-width: var.$breakpoint-tablet) {
  .results__item {
    font-size: clamp(2.5rem, 7vh, 10rem);
    line-height: clamp(1.5ch, 1vh, 100ch);
  }
}

@media (min-width: var.$breakpoint-desktop) {
  .results__item {
    font-size: 6.5rem;
    line-height: 7rem;
  }

  .results__user {
    letter-spacing: 0.125em;
  }

  .results__field {
    padding: 0 0 0 0.05em;
  }
}
