@use "../../scss/variables.scss" as var;

.age-calculator {
  $normal-corner: 24px;
  $clipped-corner: 100px;

  background-color: var.$clr-white;
  width: 100%;
  height: fit-content;
  padding: 3.5em 1em;
  border-radius: $normal-corner $normal-corner $clipped-corner $normal-corner;
}

@media (min-width: var.$breakpoint-tablet) {
  .age-calculator {
    min-width: 40rem;
    min-height: 30rem;
    height: 55vh;
    width: 75vw;
  }
}

@media (min-width: var.$breakpoint-desktop) {
  .age-calculator {
    $normal-corner: 24px;
    $clipped-corner: 200px;

    min-width: 53.5rem;
    min-height: 20rem;
    border-radius: $normal-corner $normal-corner $clipped-corner $normal-corner;
    height: auto;
    width: auto;
    padding: 3.5em;
  }
}
