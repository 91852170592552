/* poppins-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url("../media/fonts/poppins-v20-latin-italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url("../media/fonts/poppins-v20-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../media/fonts/poppins-v20-latin-700.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url("../media/fonts/poppins-v20-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: url("../media/fonts/poppins-v20-latin-800italic.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */ url("../media/fonts/poppins-v20-latin-800italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Colours
$clr-black: #151515;
$clr-purple: #854dff;
$clr-red: #ff5959;
$clr-white: #fff;
$clr-grey: #716f6f;
$clr-grey-light: #dcdcdc;
$clr-grey-vlight: #f0f0f0;

// Fonts
$font-family: "Poppins", sans-serif;
$font-weight-normal: 500;
$font-weight-semibold: 700;
$font-weight-bold: 800;

// Breakpoints
$breakpoint-tablet: 700px;
$breakpoint-desktop: 1200px;
